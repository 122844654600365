<template>
    <v-combobox :label="label" :placeholder="placeholder" ref="treeview_input" v-model="input"
        :dense="dense" :outlined="outlined" :hint="hint" :persistent-hint="persistentHint" :loading="loading" 
        clearable :disabled="disabled" clear-icon="mdi-close-circle" @update:search-input="onInputChange"  
        :menu-props="{ maxWidth: $refs.treeview_input?.$el.clientWidth }">
        <template v-slot:no-data>
            <v-treeview ref="treeview" :items="activeItems" :item-key="itemKey" :open.sync="openedTrees" dense hoverable
                transition return-object :active="selectedItems" activatable @update:active="selectTreeviewItem">
                <template v-slot:label="{ item }">
                    <div class="text-truncate" :title="item.name">{{ item.name }}</div>
                </template>
            </v-treeview>
        </template>
    </v-combobox>
</template>
<script>
export default {
    name: 'TreeviewSelect',
    props: ['label', 'placeholder', 'rawItems', 'itemKey', 'disabled', 'selectedId', 'rules', 'outlined', 'hint', 'persistent-hint', 'loading', 'dense'],
    model: {
        prop: 'selectedId',
        event: 'change'
    },
    data() {
        return {
            items: [],
            input: null,
            selectedItems: [],
            openedTrees: [],
            treesHashmap: new Map(),
            matchesOfSearch: new Map(),
            filteredItemsHashmap: new Map(),
            treesOfParentMatch: null,
            filteredItems: null
        }
    },
    watch: {
        rawItems: {
            immediate: true,
            handler(newData, prevData) {
                if (newData && newData !== prevData) {
                    let uniqueTreeviewData = new Map();

                    newData.forEach((item) => {
                        if (!uniqueTreeviewData.get(item.id)) {
                            uniqueTreeviewData.set(item.id, item);
                        }
                    })

                    this.items = this.unflatten([...uniqueTreeviewData.values()]);

                    if (this.selectedId && !this.input) {
                        let selectedItem = uniqueTreeviewData.get(this.selectedId);

                        this.filterInactiveItems(selectedItem, uniqueTreeviewData);
                        this.selectedItems = [selectedItem];
                        this.input = selectedItem.name;
                    }
                }
            }
        },
        selectedId: {
            immediate: true,
            handler(newData) {
                if (newData) {
                    let uniqueTreeviewData = new Map();

                    this.rawItems.forEach((item) => {
                        if (!uniqueTreeviewData.get(item.id)) {
                            uniqueTreeviewData.set(item.id, item);
                        }
                    })
                    let selectedItem = uniqueTreeviewData.get(newData);

                    if (selectedItem) {
                        this.filterInactiveItems(selectedItem, uniqueTreeviewData);
                        this.selectedItems = [selectedItem];
                        if (!this.$refs.treeview) this.input = selectedItem.name;
                    }

                }
                else {
                    this.input = null;
                    this.openedTrees = [];
                    this.selectedItems = [];
                }
            }
        }
    },
    computed: {
        activeItems() {
            if (this.filteredItems) {
                return this.filteredItems;
            }
            else if (this.items.length > 0) {
                return this.items;
            }
            else {
                return [];
            }
        }
    },
    methods: {
        onInputChange(value) {
            if (value && value.length > 1 && this.$refs.treeview) {
                this.input = value;
                this.treesHashmap = this.getTreeviewHashmap();
                this.filterTreeview(value);
            }
            if (!value && this.$refs.treeview) {
                this.input = null;
                this.clearTreeview();
            }
        },
        selectTreeviewItem(item) {
            if (item.length > 0) {
                this.input = item[0].name;
                this.$emit('change', item[0].id);
                this.$refs.treeview_input.blur();
            }
        },
        getTreeviewHashmap() {
            let treeviewHashmap = new Map();

            this.matchesOfSearch.clear();
            this.filteredItemsHashmap.clear()
            this.filteredItems = null;

            this.$refs.treeview.updateAll(false);
            this.$refs.treeview.buildTree(this.items);
            this.$refs.treeview.updateAll(true);

            this.openedTrees.forEach((tree) => treeviewHashmap.set(tree.id, JSON.parse(JSON.stringify(tree))));

            return treeviewHashmap;
        },
        filterTreeview(inputValue) {
            let filteredItems = [];

            this.openedTrees.forEach((tree) => this.deepSearchTree(inputValue, tree));
            this.filteredItemsHashmap.forEach((child, id) => filteredItems.push(this.getFilteredTrees(child, id)));

            this.filteredItems = filteredItems;
        },
        deepSearchTree(inputValue, tree) {
            if (tree.name.toLowerCase().includes(inputValue.toLowerCase()) && !this.matchesOfSearch.get(tree.id)) {
                this.matchesOfSearch.set(tree.id, tree);
                this.generateTreeStructureOfMatch(tree);
            }
            if (tree.children.length > 0) {
                tree.children.forEach((child) => this.deepSearchTree(inputValue, child));
            }
        },
        generateTreeStructureOfMatch(match) {
            let treeviewRef = this.$refs.treeview;

            let parentsOfMatch = treeviewRef.getParents(match.id).map((parentId) => {
                return {
                    id: parentId,
                    level: treeviewRef.getParents(parentId).length
                };
            }).sort((a, b) => a.level - b.level);

            let treeNode, treeNodeId;
            let subParentIds = [];

            parentsOfMatch.forEach((parent, index) => {
                if (parent.level === 0) {
                    if (!this.filteredItemsHashmap.get(parent.id)) {
                        this.filteredItemsHashmap.set(parent.id, { name: parent.name, id: parent.id, children: {} });
                    }
                    treeNode = this.filteredItemsHashmap.get(parent.id);
                    treeNodeId = parent.id;
                }
                if (parent.level > 0 && this.filteredItemsHashmap.get(treeNodeId)) {
                    subParentIds.push({ id: parent.id, name: parent.name })
                }
                if (index === parentsOfMatch.length - 1) {
                    this.fillTreeWithChildren(treeNode, subParentIds, match);
                }
            });

            if (parentsOfMatch.length === 0 && !this.filteredItemsHashmap.get(match.id)) {
                this.filteredItemsHashmap.set(match.id, match);
                this.openedTrees = this.treesHashmap[match.id];
            }
        },
        fillTreeWithChildren(treeNode, subParentIds, match) {
            let currentParent = subParentIds[0];

            if (subParentIds.length === 0 && !treeNode.children[match.id]) {
                if (match.children.length > 0) {
                    let copyOfMatch = JSON.parse(JSON.stringify(match));
                    treeNode.children[copyOfMatch.id] = copyOfMatch;
                    this.openedTrees = [this.treesHashmap.get(treeNode.id)];
                }
                else {
                    treeNode.children[match.id] = match;
                }
            }
            if (subParentIds.length === 1) {
                if (!treeNode.children[currentParent.id] && match.children.length === 0) {
                    treeNode.children[currentParent.id] = { children: { [match.id]: match }, name: currentParent.name };
                }

                else if (!treeNode.children[currentParent.id] && match.children.length > 0) {
                    let copyOfMatch = JSON.parse(JSON.stringify(match));

                    let parentsInChildren = { [match.id]: match }

                    copyOfMatch.children.forEach((child) => {
                        if (child.children.length > 0) {
                            parentsInChildren[child.id] = child;
                        }
                    })

                    this.treesOfParentMatch = this.openedTrees;
                    this.openedTrees = this.openedTrees.filter((tree) => !parentsInChildren[tree.id]);

                    treeNode.children[currentParent.id] = { children: { [copyOfMatch.id]: copyOfMatch }, name: currentParent.name };
                }
                else if (treeNode.children[currentParent.id] && match.children.length > 0) {
                    return;
                }
                else if (treeNode.children[currentParent.id] && match.children.length === 0) {
                    if (this.treesOfParentMatch && Array.isArray(treeNode.children[currentParent.id].children)) {
                        treeNode.children[currentParent.id].children = {};
                        this.openedTrees = this.treesOfParentMatch;
                        this.treesOfParentMatch = null;
                    }
                    treeNode.children[currentParent.id].children[match.id] = match;
                }
            }
            else {
                if (currentParent) {
                    if (!treeNode.children[currentParent.id]) {
                        treeNode.children[currentParent.id] = { children: {}, name: currentParent.name }
                    }
                    this.fillTreeWithChildren(treeNode.children[currentParent.id], subParentIds.slice(1), match);
                }
            }
        },
        getFilteredTrees(tree, treeId) {
            let parentReference = this.treesHashmap.get(treeId);

            if (parentReference) {
                parentReference.children = Object.keys(tree.children).map((childId) => this.getFilteredTrees(tree.children[childId], childId, this.treesHashmap));
                return parentReference;
            }
            else {
                return tree;
            }
        },
        clearTreeview() {
            let activeItem = this.$refs.treeview.activeCache;
            if (activeItem) { this.$refs.treeview.updateActive(activeItem.values().next().value, false) }

            this.filteredItems = null;
            this.filteredItemsHashmap.clear()
            this.matchesOfSearch.clear();

            this.$refs.treeview.updateAll(false);
        },
        unflatten(array, parent, tree) {
            var that = this
            tree = typeof tree !== 'undefined' ? tree : []
            parent = typeof parent !== 'undefined' ? parent : { id: null }

            var children = array.filter(function (child) { return child.parentOrganizationId === parent.id })

            if (children.length) {
                if (!parent.id) {
                    tree = children
                } else {
                    parent['children'] = children
                }
                children.forEach(function (child) { that.unflatten(array, child) })
            } else { parent['children'] = [] }

            return tree
        },
        filterInactiveItems(item, itemsHashmap, parents, tree) {
            let parentOrganizations = parents ?? [];
            let filteredTree = tree ?? [];

            if (item.parentOrganizationId) {
                let prevItem = filteredTree[0] ?? item;
                let currentParent = JSON.parse(JSON.stringify(itemsHashmap.get(item.parentOrganizationId)));

                currentParent.children = [prevItem];
                filteredTree = [currentParent]
                parentOrganizations.push(currentParent);

                this.filterInactiveItems(currentParent, itemsHashmap, parentOrganizations, filteredTree)
            }
            else {
                if (filteredTree.length > 0) this.filteredItems = filteredTree;
                this.openedTrees = parentOrganizations;
            }
        }
    }
}
</script>
<style>
.v-treeview-node__root {
    cursor: pointer;
}

.v-treeview-node__root.v-treeview-node--active {
    pointer-events: none;
}

.v-treeview-node__toggle {
    pointer-events: all;
}
</style>